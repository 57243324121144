<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        <span @click="enableAddBtnCount+=1">Services</span>
                        <v-spacer></v-spacer>

                        <add-button
                                v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13"
                                :permission="true"
                                @action="form.dialog = true">
                            New Service
                        </add-button>

                    </v-card-title>

                    <v-data-table :headers="headers" :hide-default-footer="!form.items.data" :items="form.items.data" :search="search" :options.sync="pagination" :server-items-length="form.items.meta.total">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.ip }}</td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <td class="text-xs-left">{{ item.domain }}</td>
                                <td class="text-xs-left">{{ item.status }}</td>
                                <td class="text-xs-right">
                                    <edit-button v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13" permission="edit-service" @agree="form.edit(item)"/>
                                    <delete-button v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13" permission="delete-service" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Service Information</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                lazy-validation>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-text-field outlined dense label="Service Name*" autocomplete="off" required class="pa-0"
                                                      v-model="form.name" name="name"
                                                      :error-messages="form.errors.get('name')"/>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-text-field outlined dense label="Ip*" autocomplete="off" required class="pa-0"
                                                      v-model="form.ip" name="ip"
                                                      :error-messages="form.errors.get('ip')" id="ip"/>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                        <v-text-field outlined dense label="Domain*" autocomplete="off" required class="pa-0"
                                                      v-model="form.domain" name="domain"
                                                      :error-messages="form.errors.get('domain')" id="domain"/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-select outlined dense v-model="form.status" :items="status" label="Select Status"
                                                  class="pt-0"/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="store">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                name: '',
                ip: '',
                domain: '',
                status: 'active',
            }, '/api/service'),
            search: null,
            enableAddBtnCount: 0,
            pagination: {
                page: 1,
                itemsPerPage: 10
            },
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 80},
                {text: 'Ip', align: 'left', value: 'ip', sortable: false},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Domain', align: 'left', value: 'domain', sortable: false},
                {text: 'Status', align: 'left', value: 'status'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            status: [
                {
                    text: 'Active',
                    value: 'active'
                }, {
                    text: 'Inactive',
                    value: 'inactive'
                }
            ]
        }),
        watch: {
            'pagination': function () {
                this.get();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
					if(typeof json[key] == 'object') {
						let vals = [...json[key]];
                        if(vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
					}
					return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
				}).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                this.form.store();
            },

            enableAdd() {

            }

        }
    }
</script>
<style lang="scss" scoped></style>